/* eslint-disable @typescript-eslint/no-unused-vars */
import { SomethingWentWrong } from 'components/errors/something-went-wrong';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;
type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }

    return this.props.children;
  }
}
