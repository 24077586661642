import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { updateWorkflowDetailsRequestBody, GroupByEnum } from 'services/production-workflow.model';
import { ProductionListActions } from 'pages/production/controllers/production-list-controller/production-list.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';

export const MAX_LETTERS = 1000;

export type EditNoteArgs = {
  initialNote: string;
  rootMetaId?: string;
  productionId: string;
  rootOrderId?: string;
};

export type EditNoteState = {
  note: string;
  rootMetaId: string;
  isLoading: boolean;
  rootOrderId: string;
  productionId: string;
  isConfigurationChange: boolean;
};

const defaultState: EditNoteState = {
  note: '',
  rootMetaId: '',
  rootOrderId: '',
  productionId: '',
  isLoading: false,
  isConfigurationChange: false,
};

const stateController = new StateController<EditNoteState>('EDIT_NOTE', defaultState);

export class EditNoteActions {
  public static init({ initialNote, productionId, rootMetaId, rootOrderId }: EditNoteArgs) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rootMetaId,
          rootOrderId,
          productionId,
          note: initialNote,
        })),
      );
    };
  }

  public static dispose() {
    return async (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static saveChanges(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { productionId, note, isConfigurationChange } = getState().production.editNote;
        let body: updateWorkflowDetailsRequestBody = {};

        if (isConfigurationChange) {
          body = { product_configuration: note };
        } else {
          body = { note };
        }
        await ProductionWorkflowService.updateProductionWorkflowDetails(productionId, body);

        if (page === Page.InfoDropdownWorkflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
          await loadData(productionId);
        }
        if (page === Page.InfoDropdownProduction) {
          const { groupBy } = getState().production.filters;
          const { rootOrderId, rootMetaId } = getState().production.editNote;

          await loadData(productionId);

          if (groupBy === GroupByEnum.None) {
            await dispatch(
              ProductionFiltersActions.getProductionsByFilter({
                customGroupBy: groupBy,
                showFetchEffect: false,
                resetSkipPreserveTake: true,
                resetOpenedProductionGroups: false,
              }),
            );
          } else {
            let groupId: string = '';
            if (groupBy === GroupByEnum.Order) {
              groupId = rootOrderId;
            } else if (groupBy === GroupByEnum.Product) {
              groupId = rootMetaId;
            }
            dispatch(ProductionListActions.getTargetGroupData(groupId));
          }
        }
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static onChange(value: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      if (value.length <= MAX_LETTERS) {
        dispatch(stateController.setState({ note: value }));
      }
    };
  }

  public static onCancel(value: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      dispatch(stateController.setState({ note: value }));
    };
  }

  public static onChangeNoteMode(value: boolean) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      dispatch(stateController.setState({ isConfigurationChange: value }));
    };
  }
}

export const reducer = stateController.getReducer();
