/* eslint-disable consistent-return */
import { useEffect } from 'react';
//! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
// import { useChatWidget } from 'hooks/use-chat-widget';

const getTabId = () => {
  if (!window.name) {
    window.name = `tab-${Math.random().toString(36)}`;
  }
  return window.name;
};

const useDisableDocumentScroll = (isScrollDisabled: boolean, modalKey: string) => {
  // modalKey must be unique!

  // === Keys already used: ===
  // modal
  // moveModal
  // mediaModal
  // infoDropdown
  // dropdownMenu
  // editOrderModal
  // editClientModal
  // editVariantModal
  // addPositionModal
  // prioritySelector
  // newProductionModal
  // textEditableSelect
  // productsLaunchModal
  // productPreviewModal
  // workflowPreviewModal
  // printLabelWithQrModal
  // addParameterTemplateModal
  // dropdownUserSearchSelector
  // textEditableWithAutosuggest
  // taskTemplateResponsibilityModal
  // workflowTaskTemplatePreviewModal

  const tabId = getTabId();

  useEffect(() => {
    // Temporary condition
    if (localStorage.getItem('scrollStateObj')) {
      localStorage.removeItem('scrollStateObj');
    }

    const scrollStateObjKey = `scrollStateObj-${tabId}`;
    const scrollStateObj = JSON.parse(sessionStorage.getItem(scrollStateObjKey) || '{}');

    if (isScrollDisabled) {
      scrollStateObj[modalKey] = true;
    } else {
      delete scrollStateObj[modalKey];
    }

    sessionStorage.setItem(scrollStateObjKey, JSON.stringify(scrollStateObj));

    const isScrollActive = document.documentElement.scrollHeight > window.innerHeight;

    if (!isScrollActive) return;

    const { userAgent } = navigator;

    const scrollbarWidth = userAgent.indexOf('Firefox') > -1 ? 17.26 : 2.66;

    const toggleScroll = (shouldBlock: boolean) => {
      //! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
      // const translateValue = shouldBlock ? scrollbarWidth : 0;
      document.documentElement.style.overflow = shouldBlock ? 'hidden' : '';
      document.documentElement.style.paddingRight = shouldBlock ? `${scrollbarWidth}px` : '';
      //! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
      // useChatWidget().setTransform(`translateX(-${translateValue}px)`);
    };

    const shouldBlockScroll = Object.keys(scrollStateObj).length > 0;

    toggleScroll(shouldBlockScroll);

    return () => {
      const updatedScrollStateObj = JSON.parse(sessionStorage.getItem(scrollStateObjKey) || '{}');
      delete updatedScrollStateObj[modalKey];

      sessionStorage.setItem(scrollStateObjKey, JSON.stringify(updatedScrollStateObj));

      const shouldBlockScrollAfterUnmount = Object.keys(updatedScrollStateObj).length > 0;

      toggleScroll(shouldBlockScrollAfterUnmount);
    };
  }, [isScrollDisabled, modalKey, tabId]);
};

export default useDisableDocumentScroll;
