export type HighlitedText = {
  inputValue: string;
  highliteValue: string;
  searchValue: string;
};

export const replaceStr = (inputValue: string, searchValue: string, shouldHighlight = true) => {
  if (!inputValue || !searchValue) return inputValue;

  const escapeRegexChars = (str: string): string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const escapedSearchValue = escapeRegexChars(searchValue);
  const pattern = new RegExp(escapedSearchValue, 'gi');

  return inputValue.replace(pattern, (match) => (shouldHighlight ? `<span>${match}</span>` : match));
};

export const getEscapedSearchValue = (value: string) => {
  if (!value) {
    return '';
  }
  // Escape special characters for regex
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const searchReplacer = (inputValue: string, searchValue: string, style?: string): HighlitedText => {
  // Escape special characters for regex
  const escapedSearchValue = getEscapedSearchValue(searchValue);

  const regex = new RegExp(`(${escapedSearchValue || ''})`, 'gi');

  const highliteValue = inputValue.replace(regex, (match) => {
    if (!match) return '';
    return `<span class="search-match" style="${style || 'border-radius: 0px; background: #c2d3ff;'}">${match}</span>`;
  });

  return { inputValue, highliteValue, searchValue: escapedSearchValue };
};
