import {
  MapAssignAtArgs,
  UpdateTasksParamsArgs,
  UpdatedTaskResponsibilitiesValuesReturnT,
} from 'pages/tasks/types/function-types';
import { IdName } from 'types/common-types';
import { UNASSIGNED } from 'constants/unassigned';
import { SelectedTaskT, TaskTableStateT } from 'pages/tasks/types/types';
import { CreateUpdateTaskResponse } from 'services/production-task.model';
import { AssignResponsibleToTasksBodyArgs } from 'services/task-table.model';

export const getAllTasksOnTheScreen = (tasks: TaskTableStateT[]): SelectedTaskT[] => {
  if (!Array.isArray(tasks) || tasks.length === 0) {
    return [];
  }

  return tasks.map((task) => ({
    id: task.id,
    departmentIds: task.department_ids,
    slots: task.assignee.map((assignee) => ({
      slot_id: assignee.slot_id,
      task_responsibility_id: assignee.task_responsibility_id,
    })),
  }));
};

export const getResponsibleId = (responsibleId: string | undefined): string | null => {
  if (responsibleId === UNASSIGNED) return null;
  return responsibleId || null;
};

export const prepareRequestBodyForSingleBulkAssignUsers = (
  performerId: string | null,
  tasks: SelectedTaskT[],
): AssignResponsibleToTasksBodyArgs[] => {
  const taskSlotIds = tasks.flatMap((task) => task.slots.map((slot) => slot.slot_id)).filter((slotId) => slotId);

  return [
    {
      user_id: performerId,
      task_slot_ids: taskSlotIds,
    },
  ];
};

export const prepareRequestBodyForMultipleBulkAssignUsers = (
  performers: Record<string, string | null>,
  tasks: SelectedTaskT[],
): AssignResponsibleToTasksBodyArgs[] => {
  const performerEntries = Object.entries(performers).map(([index, userId]) => ({ index: Number(index), userId }));

  return performerEntries.map(({ index, userId }) => {
    const taskSlotIds = tasks.map((task) => task.slots[index]?.slot_id);

    return {
      user_id: userId,
      task_slot_ids: taskSlotIds,
    };
  });
};

export const collectAllTaskSlotIds = (assignments: AssignResponsibleToTasksBodyArgs[]): string[] => {
  return assignments.flatMap((assignment) => assignment.task_slot_ids);
};

export const updateTasksFromWsResponse = ({ id, slotId, user, tasks, isUnassignFinishedEvent }: UpdateTasksParamsArgs) => {
  const currentAssignAt = new Date().toISOString();

  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id !== id) {
        return task;
      }

      const newPositions = isUnassignFinishedEvent
        ? []
        : user?.position?.map((pos) => ({
            id: pos.id,
            name: pos.name,
            slot_id: slotId,
          })) || [];

      const newDepartments = isUnassignFinishedEvent
        ? []
        : user?.department?.map((dep) => ({
            id: dep.id,
            name: dep.name,
            slot_id: slotId,
          })) || [];

      return {
        ...task,
        assignee: task.assignee.map((assignee) => {
          if (assignee.slot_id === slotId) {
            return {
              ...assignee,
              taskAssignment: isUnassignFinishedEvent ? null : { user },
            };
          }
          return assignee;
        }),
        task_slots: task.task_slots?.map((slot) => {
          if (slot.id === slotId) {
            return {
              ...slot,
              ...(isUnassignFinishedEvent ? {} : { task_assignment_id: user?.id }),
            };
          }
          return slot;
        }),
        is_assignment_in_progress: false,
        // remember columns may be inactive
        assignee_position: [...(task.assignee_position?.filter((pos) => pos.slot_id !== slotId) || []), ...newPositions],
        assignee_department: [...(task.assignee_department?.filter((dep) => dep.slot_id !== slotId) || []), ...newDepartments],
        assigned_at:
          task.assigned_at?.map((assigned) => {
            if (assigned.id === slotId) {
              return {
                id: slotId,
                name: isUnassignFinishedEvent ? '' : currentAssignAt,
              };
            }
            return assigned;
          }) || [],
      };
    }),
  };
};

export const extractUpdatedTaskResponsibilitiesValues = (data: CreateUpdateTaskResponse) => {
  return data.responsibilities.reduce<UpdatedTaskResponsibilitiesValuesReturnT>(
    (acc, responsibility) => {
      responsibility.taskSlots.forEach((taskSlot) => {
        const { taskAssignment } = taskSlot;

        if (taskAssignment?.assign_at) {
          acc.assignAt.push({
            id: taskSlot.id,
            name: taskAssignment.assign_at,
          });
        }

        if (taskAssignment) {
          const { user } = taskAssignment;
          if (user?.user_position_slots?.length) {
            user.user_position_slots.forEach((positionSlot) => {
              const position = positionSlot?.position_slot;

              acc.positions.push({
                id: positionSlot?.position_slot?.position_type_id,
                name: position?.position_type?.name || '',
                slot_id: taskSlot.id,
              });
              acc.departments.push({
                id: positionSlot?.position_slot?.department_id,
                name: position?.department?.name || '',
                slot_id: taskSlot.id,
              });
            });
          }
        }
      });

      return acc;
    },
    {
      positions: [],
      departments: [],
      assignAt: [],
    },
  );
};

export const mapAssignAt = ({ assignee, assigned_ats = [] }: MapAssignAtArgs): IdName[] => {
  return assignee.map((slot) => {
    const assigned_at = slot.taskAssignment ? assigned_ats.shift() || '' : '';

    return {
      id: slot.slot_id,
      name: assigned_at,
    };
  });
};
